<template>
  <b-container class="page-container">
    <h1>{{ this.$t('confirmation-checkout') }}</h1>

    <h4>{{ this.$t('overview-of-your-order') }}</h4>
    <b-row>
      <b-col md="4">
        <b-card class="h100">
          <div>
            <strong>{{ this.$t('reservation-id') }}</strong>
            <p>{{ reservation.ReservationID }}</p>
          </div>
          <div>
            <strong>{{ this.$t('retrieval-location') }}</strong>
            <p>{{ reservation.WarehouseID }}</p>
          </div>
          <div>
            <strong>{{ this.$t('retrieval-date') }}</strong>
            <p>
              {{ format(reservation.DateTimeBusinessStart) }}
            </p>
          </div>
          <div>
            <strong>{{ this.$t('return-date') }}</strong>
            <p>
              {{ format(reservation.DateTimeExpectedEnd) }}
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-table :items="reservation.ReservationItems" :fields="fields" />
      </b-col>

      <b-col cols="12" class="mt-3">
        <h3>{{ this.$t('order-more') }}</h3>

        <router-link
          :to="{ name: 'items' }"
          class="btn btn-primary mt-2"
          variant="primary"
          >{{ this.$t('place-new-reservation') }}
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export default {
  data() {
    return {
      fields: [
        {
          key: 'ItemDescription',
          label: this.$t('product')
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'Amount',
          label: this.$t('amount'),
          class: 'text-center'
        }
      ]
    }
  },
  computed: {
    reservation: function() {
      let reservationData = this.$store.getters.reservation.data

      reservationData.ReservationItems = reservationData.ReservationItems.filter(
        function(obj) {
          return obj.Description !== 'Administratiekosten'
        }
      )
      return reservationData
    }
  },
  methods: {
    format(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.prices {
  padding-right: 10px;
}
h1 {
  font-size: 50px;
}
h3 {
  font-size: 35px;
}
.card,
p,
.table {
  font-size: 18px;
}
.card {
}
a {
  text-decoration: none;
}
.price-values {
  p {
    padding-bottom: 3px !important;
  }
}
</style>
